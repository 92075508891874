export const siteNavLinks = [
  {
    name: 'About',
    url: '/about',
    isExternal: false,
  },
  {
    name: 'Blog',
    url: 'https://blog.dotcampus.co',
    isExternal: true,
  },
  {
    name: 'Pricing',
    url: '/pricing',
    isExternal: false,
  },
  {
    name: 'FAQs',
    url: '/about#faqs',
    isExternal: false,
  },
  {
    name: 'Webinar',
    url: '/webinar',
    isExternal: false,
  },
]
