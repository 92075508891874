import { Card, CardBody, Stack, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import { Webinar } from '../../../constants'

export const WebinarCard = ({
  webinar,
}: {
  webinar: Webinar
}): React.ReactElement | null => {
  if (!webinar.videoId) {
    return null
  }

  return (
    <Card
      boxShadow="md"
      width="350px"
      minWidth="350px"
      data-testid="webinar-card"
    >
      <CardBody>
        {webinar.videoId && (
          <iframe
            width="350"
            height="257"
            src={`https://www.youtube.com/embed/${webinar.videoId}`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
          ></iframe>
        )}

        <Stack spacing="3" p="4">
          <Heading fontSize="xl">{webinar.title}</Heading>
          <Text>{webinar.description}</Text>
        </Stack>
      </CardBody>
    </Card>
  )
}
